<template>
    <div class="contact">
        <div class="banner-cantact">
            <img src="@/assets/images/contact-banner.png"/>
        </div>

        <div class="message-box">
            <div :class="['panel-item', active === 1 ? 'show' : 'hide']">
                <div class="title">You need to fill in the following questions</div>
                <div class="note">
                    <img src="@/assets/images/date.png" class="date"/>
                    <div class="text">Takes 7 minutes</div>
                </div>

                <div class="button" @click="active = 2">Start</div>
            </div>

            <div :class="['panel-item', active === 2 ? 'show' : 'hide']">
                <div class="input-box">
                    <div class="input-title">1&emsp;First name</div>
                    <input class="txt" type="text" placeholder="Type your answer here..." v-model="value"/>
                    <div class="button" @click="active = 3">ok</div>
                </div>
            </div>

            <div :class="['panel-item', 'panel-end', active === 3 ? 'show' : 'hide']">
                <div class="success-box">
                    <img src="@/assets/images/success.png"/>
                    <div class="mark">Submitted Successfully</div>
                    <div class="text">We have received your application and will contact you later.</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data: () => ({
        active: 1,
        value: ""
    })
}
</script>

<style scoped lang="scss">
.banner-cantact {
    display: flex;
    width: 100%;

    > img {
        width: 100%;
        height: 382px;
    }
}

.message-box {
    width: 100%;
    height: 450px;

    padding-top: 68px;
    background-color: #FFFFFF;

    .panel-item {
        display: flex;
        flex-direction: column;
        align-items: center;

        width: 100%;

        &.hide {
            display: none;
        }

        &.show {
            display: flex;
        }

        .title {
            font-size: 22px;
            font-weight: 300;
            line-height: 38px;
            color: #1E1E1E;
        }

        .note {
            display: flex;
            align-items: center;

            > .date {
                width: 16px;
                height: 16px;
                margin-right: 8px;
            }

            > .text {
                font-size: 16px;
                font-weight: 300;
                line-height: 38px;
                color: #1E1E1E;
            }
        }

        .button {
            display: flex;
            align-items: center;
            justify-content: center;

            width: 164px;
            height: 48px;

            font-size: 18px;
            font-weight: 400;
            color: #FFFFFF;
            margin-top: 32px;
            border-radius: 5px;
            background-color: #0B7BD0;
            box-shadow: 0px 4px 4px 0px #0000001A;

            &:hover {
                cursor: pointer;
                opacity: .8;
            }
        }

        .input-box {
            width: 764px;

            .input-title {
                font-size: 22px;
                font-weight: 400;
                line-height: 38px;
                letter-spacing: 1px;
                color: #1E1E1E;
                margin-bottom: 36px;
            }

            .txt {
                width: 100%;
                outline: none;
                border: 0px;
                border-bottom: 1px #0B7BD0 solid;
                padding: 2px 0 8px 0;
                font-size: 24px;
                font-weight: 400;
                line-height: 38px;
                color: rgba(11, 123, 208, 1);
                margin-bottom: 16px;

                &::placeholder {
                    color: rgba(0, 69, 122, 0.3)
                }
            }

            .button {
                width: 98px;
                text-transform: uppercase;
                margin-top: 0px;
            }
        }

        .success-box {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin-top: 16px;

            > img {
                width: 86px;
                height: 86px;
                margin-bottom: 30px;
            }

            .mark {
                font-size: 22px;
                font-weight: 600;
                line-height: 38px;
                color: #1E1E1E;
            }

            .text {
                font-size: 22px;
                font-weight: 300;
                line-height: 38px;
                color: #1E1E1E;
            }
        }
    }
}
</style>